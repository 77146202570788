import { Input } from 'components/ui'
import PatternFormat from 'react-number-format'


const NumberInput = ({
    inputSuffix,
    inputPrefix,
    ...props
}) => {
    return (
        <Input
            {...props}
            value={props.value}
            suffix={inputSuffix}
            prefix={inputPrefix}
        />
    )
}

const NumberFormatInput = ({
    onValueChange,
    form,
    field,
    ...rest
}) => {
    return (
        <PatternFormat
            customInput={NumberInput}
            form={form}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            field={field}
            onBlur={field?.onBlur}
            onValueChange={onValueChange}
            {...rest}
        />
    )
}

const FormPatternInput = ({
    form,
    field,
    inputSuffix,
    inputPrefix,
    onValueChange,
    ...rest
}) => {
    return (
        <NumberFormatInput
            form={form}
            field={field}
            inputPrefix={inputPrefix}
            inputSuffix={inputSuffix}
            onValueChange={onValueChange}
            {...rest}
        />
    )
}

export default FormPatternInput
